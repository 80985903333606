import "core-js/modules/es.number.constructor.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mj-calendar-event__icon"
};
var _hoisted_2 = {
  class: "mj-calendar-event__content"
};
export default {
  __name: 'MJCalenderEvent',
  props: {
    content: String,
    color: String,
    smsStatus: [String, Number]
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_EventTypeIcon = _resolveComponent("EventTypeIcon");

      return _openBlock(), _createElementBlock("div", {
        class: "mj-calendar-event",
        style: _normalizeStyle({
          '--mj-calendar-event-color': __props.color || null
        })
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_EventTypeIcon, {
        type: __props.smsStatus
      }, null, 8, ["type"])]), _createElementVNode("div", _hoisted_2, _toDisplayString(__props.content), 1)], 4);
    };
  }
};